<template>
  <div align="center">

  <ProgressState :position="2" />
  <SelectedCard editLink="/step2" />

  <div style="margin-top:5px;margin-bottom:25px;font-size:14px;">
      Führen Sie immer eine Plausibilitätskontrolle  des berechneten Werts durch!
  </div>

    <el-tabs type="border-card" align="center" class="boxed tab_box">
      <el-tab-pane label="mmol">
        <div class="block" style="margin-bottom:15px;">
            <el-slider
              v-model="custFill"
              input-size="small"	
              :show-input="true"
              :show-tooltip="false"
              :show-input-controls="false"
              :max=this.contFill
              :marks="marks"
              @input="updateData()"
              >
            </el-slider >
        </div>

        <el-table
          :data="tableData"
          stripe
          border          
          style="width: 100%">
          <el-table-column
            prop="what"
            label=""
            >
          </el-table-column>
          <el-table-column label="Millimol (mmol) je" align="center">
            <el-table-column
              prop="mol_liter"
              align="center"
              label="Liter"
              width="95">
            </el-table-column>
            <el-table-column
              prop="mol_sel"
              align="center"
              label="Behältnis"
              width="95">
            </el-table-column>
            <el-table-column
              prop="mol_cust"
              align="center"
              :label=(this.custFill.toString().concat(strMl))
              width="95">
            </el-table-column>
          </el-table-column>
        </el-table>
        
      </el-tab-pane>

      <el-tab-pane label="mg">
        <div class="block" style="margin-bottom:15px;">
            <el-slider
              v-model="custFill"
              input-size="small"	
              :show-input="true"
              :show-tooltip="false"
              :show-input-controls="false"
              :max=this.contFill
              :marks="marks"
              @input="updateData()"
              >
            </el-slider >
        </div>

        <el-table
          :data="tableData"
          stripe
          border          
          style="width: 100%">
          <el-table-column
            prop="what"
            label=""
            >
          </el-table-column>
          <el-table-column label="Milligramm (mg) je" align="center">
            <el-table-column
              prop="mg_liter"
              align="center"
              label="Liter"
              width="95">
            </el-table-column>
            <el-table-column
              prop="mg_sel"
              align="center"
              label="Behältnis"
              width="95">
            </el-table-column>
            <el-table-column
              prop="mg_cust"
              align="center"
              :label=(this.custFill.toString().concat(strMl))
              width="95">
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane>


      <el-tab-pane label="g">
        <div class="block" style="margin-bottom:15px;">
            <el-slider
              v-model="custFill"
              input-size="small"	
              :show-input="true"
              :show-tooltip="false"
              :show-input-controls="false"
              :max=this.contFill
              :marks="marks"
              @input="updateData()"
              >
            </el-slider >
        </div>

        <el-table
          :data="tableData"
          stripe
          border          
          style="width: 100%">
          <el-table-column
            prop="what"
            label=""
            >
          </el-table-column>
          <el-table-column label="Gramm (g) je" align="center">
            <el-table-column
              prop="g_liter"
              align="center"
              label="Liter"
              width="95">
            </el-table-column>
            <el-table-column
              prop="g_sel"
              align="center"
              label="Behältnis"
              width="95">
            </el-table-column>
            <el-table-column
              prop="g_cust"
              align="center"
              :label=(this.custFill.toString().concat(strMl))
              width="95">
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane>

    </el-tabs>

    <el-popconfirm
      confirm-button-text='OK'
      @confirm="resetAll()"
      cancel-button-text='Nein, doch nicht.'
      icon="el-icon-info"
      icon-color="red"
      title="Daten zurücksetzen?"
      confirm=resetAll();
    >
      <el-button slot="reference" style="margin-top:40px;margin-bottom:20px;" type="primary">Neue Berechnung starten</el-button>
    </el-popconfirm>

  </div>

</template>





<script>
  import ProgressState from '@/components/ProgressState.vue'
  import SelectedCard from '@/components/SelectedCard.vue'

  export default {
    data() {
      return {
        upd: false,
        marks: {},
        contFill: 0,
        custFill: 0,
        strMl: " ml",
        tableData: [],
        tableDataSets: [
          { id: 10, display :'Na', nutName: 'conNa' },
          { id: 20, display :'K', nutName: 'conK' },
          { id: 30, display :'Ca', nutName: 'conCa' },
          { id: 40, display :'Mg', nutName: 'conMg' },
          { id: 50, display :'Cl', nutName: 'conCl' },
          { id: 55, display :'Glucose', nutName: 'conGlucose' },
          { id: 60, display :'Acetat', nutName: 'conAcetat' },
          { id: 70, display :'Phosphat', nutName: 'conPhosphat' },
          { id: 80, display :'Aspartat', nutName: 'conAspartat' },
          { id: 90, display :'Malat', nutName: 'conMalat' },
          { id: 100, display :'Glucose Phosphat', nutName: 'conGlucosePhosphat' },
          { id: 110, display :'HCO3', nutName: 'conHCO3' },
        ],
      }
    },  

    methods: {
      // update data
      updateData ()
      {
        if (!this.hasSolSelected()) {
          this.showMsg()
          this.$router.push('/')
        } else {
          this.fillData ()
          this.$forceUpdate()
          this.$customFill = this.custFill 
        } 
      },
      // fill data in array
      fillData ()
      {
        // hack weg 
        //this.$DS[1].sel = 1
        //this.$DC[1].sel = 1          
        // hack ende
        this.updateContFill()

        var val // mol per liter
        var absTotalAmount // mol absolut
        var amount // amount to add
        var valPerLiter // mol per liter of to add
        var nc = ""
        this.tableData = []
        this.tableDataSets.forEach((value, index) => {
          val = 0;
          absTotalAmount = 0 
          this.$DS.filter(function(item){return item.sel>0}).forEach((data, index) => {
            amount = data.sel * data.size
            valPerLiter = this.$DN[data.nutCat][value.nutName]
            absTotalAmount += this.literToCustom(valPerLiter, amount)
          })
          this.$DC.filter(function(item){return item.sel>0}).forEach((data, index) => {
            amount = data.sel * data.size
            valPerLiter = this.$DN[data.nutCat][value.nutName]
            absTotalAmount += this.literToCustom(valPerLiter, amount)
          })
                    
          //val += this.$DN[data.nutCat][value.nutName] 
          val = this.customToLiter(absTotalAmount, this.contFill); //

          this.tableData.push({
            what: value.display,
            mol_liter: this.formN(val),
            mol_sel: this.formN( this.literToCustom(val, this.contFill), false ),
            mol_cust: this.formN( this.literToCustom(val, this.custFill) ),
            mg_liter: this.formN(this.molToMg(value.nutName, val), false),
            mg_sel: this.formN(this.molToMg(value.nutName, this.literToCustom(val, this.contFill)), false),
            mg_cust: this.formN(this.molToMg(value.nutName, this.literToCustom(val, this.custFill)), false),
            g_liter: this.formN(this.molToG(value.nutName, val)),
            g_sel: this.formN(this.molToG(value.nutName, this.literToCustom(val, this.contFill))),
            g_cust: this.formN(this.molToG(value.nutName, this.literToCustom(val, this.custFill))),
          })
        }) 
      },
      
      formN(num, cut=false) {
        var ret = ""
        if ( num === null || num == 0 )
        {
          ret = "-"
        } else {
          if (cut) {
            ret = "" + Math.round(num) 
          } else {
            ret = "" + Math.round((num) * 100) / 100
          }
        }
        return ret
      },
        
      // convertions
      customToLiter(valueCustom, amountCustom) {
        return (valueCustom / amountCustom * 1000)
      },
      literToCustom(valueLiter, customAmount) {
        return (valueLiter / 1000 * customAmount)       
      },
      molToMg(what, mol) {
        return (mol * this.$DM[what]) 
      },
      molToG(what, mol) {
        return (mol * this.$DM[what] / 1000) 
      },

      // getter
      updateContFill(){
        var val = 0;
        this.$DS.filter(function(item){return item.sel>0}).forEach((value, index) => {
          val += value.sel * value.size;
        });
        this.$DC.filter(function(item){return item.sel>0}).forEach((value, index) => {
          val += value.sel * value.size;
        });
        this.contFill = val;
        this.marks = {}
        this.marks[val/4] = {
          style: { fontSize:'0.7em' },
          label: this.$createElement('normal', '25%') 
        }
        this.marks[val/2] = {
          style: { fontSize:'0.7em' },
          label: this.$createElement('normal', '50%') 
        }
        this.marks[(val/4)*3] = {
          style: { fontSize:'0.7em' },
          label: this.$createElement('normal', '75%') 
        }
      },

      // reset
      resetAll(){
        this.resetCon();
        this.resetSol();
        this.custFill = 0
        this.$customFill = 0
        this.$router.push('/')
      },
      resetCon(){
        this.$DC.forEach((value, index) => {
          value.sel=0;
        });
        this.updateData();
      },
      resetSol(){
        this.$DS.forEach((value, index) => {
          value.sel=0;
        });
      },
      showMsg()
      {
        this.$notify({
          title: 'Lösung wählen',
          message: 'Bitte zuerst eine Lösung wählen!',
          duration: 2000,
          type: 'warning',
          showClose: false
        });
      },
      hasSolSelected(){
        var solSelected = false
        this.$DS.forEach((value, index) => {
          if (value.sel > 0) {
            solSelected = true
          }
        })
        return solSelected
      }
    },    

    components: {
      SelectedCard, ProgressState
    },
    created() {
      this.updateData();
      this.custFill = this.$customFill 
    }
  }

</script>


<style>
  .tab_box .el-table,.tab_box  .cell {
    word-break: keep-all !important;
  }
  .tab_box .el-slider__runway.show-input {
    margin-right: 90px !important;
  }
  .tab_box .el-input-number {
    width:70px !important;
  }
</style>