<template>
   <el-card class="boxed box-card" id="SelectedCard">
        <span style="display: none;">{{upd}}</span>
        <el-row style="margin-bottom:10px;">
          <el-col :span="22">
            <div>
              <div class="text item" align="left" v-for="ds in this.$DS.filter(function(item){return item.sel>0})" :key="ds.id"  >
                <b>{{ ds.sel }} x {{ ds.name }} - {{ ds.size }} ml</b>
              </div>
              <div class="text item" align="left" v-for="dc in this.$DC.filter(function(item){return item.sel>0})" :key="dc.id"  >
                +  {{ dc.sel }} x {{ dc.name }} - {{ dc.size }} ml  
              </div>
            </div>
          </el-col>
          <el-col :span="2" align="right">
              <el-button @click="$router.push(editLink)" size="mini" style="align:right;" type="primary" icon="el-icon-edit" circle></el-button>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" align="left">
            <span class="text item" align="left" v-for="ds in this.$DS.filter(function(item){return item.sel>0})" :key="ds.id"  >
              <img  height="100" :src="getImgUrl(ds)">
            </span>
            <span class="text item" align="left" v-for="dc in this.$DC.filter(function(item){return item.sel>0})" :key="dc.id"  >
              <span v-for="index in dc.sel" :key="index"  >
                <img height="60" :src="getImgUrlDC(dc)">
              </span>
            </span>
          </el-col>

        </el-row>
    </el-card>
</template>

<script>
    export default {
      name: 'SelectedCard',
      props: {
        editLink: String,
        upd: Boolean
      },
      methods: {
        getImgUrl(obj)
        {
          return require('@/assets/img/packSol/' + obj.img)
        },
        getImgUrlDC(obj)
        {
          return require('@/assets/img/packCon/' + obj.img)
        },
      },
    }
</script>


<style lang="scss" scoped>
  .text {
    font-size: 14px;
  }
  .item {
    margin: 0px;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .box-card {
    border-color: #ddd;
  }

  #SelectedCard {
    margin-top:30px;
    margin-bottom:20px;
  }

  img {
    margin-right:5px;
  }

</style>